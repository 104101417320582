import React from "react"
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import * as styles from "./contact-us.module.css"

export default function Contact() {
  return (
    <Row id="contact" className={styles.bg + ` pt-3`}>
      <Col xs="12" className="text-center">
        <h2>
          <span id="contact">Get in touch!</span>
        </h2>
        <p>We're waiting to help you, so please drop us a line!</p>
      </Col>

      <Col className="d-flex justify-content-center">
        <Form
          name="get-in-touch"
          data-netlify="true"
          netlify-honeypot="bot-field"
          method="POST"
        >
          <InputGroup className="mb-3">
            <Form.Control type="hidden" name="bot-field" />
            <Form.Control type="hidden" name="form-name" value="get-in-touch" />
            <Form.Label srOnly htmlFor="email-address">
              Enter Your Email Address
            </Form.Label>
            <Form.Control
              type="email"
              required
              name="email-address"
              id="email-address"
              placeholder="Enter your email address"
              inline="true"
            />
            <InputGroup.Append>
              <Button type="submit" variant="light" className="ml-1">
                Submit
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      </Col>
    </Row>
  )
}
